"use client";
import React from "react";
import location from "@/public/images/location.svg";
import phone from "@/public/images/phone.svg";
import email from "@/public/images/email.svg";
import Image from "next/image";
import faceboock from "@/public/images/faceboock.svg";
import telegram from "@/public/images/telegram.svg";
import linkedin from "@/public/images/linkedin.svg";
import twitter from "@/public/images/twitter.svg";
import instegram from "@/public/images/instegram.svg";
import whatsApp from "@/public/images/whatsApp.svg";
import rascoda from "@/public/images/rascoda_logo.svg";
import logo from "@/public/images/logo site.png";
import MyLink from "../myLink/MyLink";
const index = () => {
  return (
    <footer className=" bg-[#5F4D46] py-10">
      <div className="  ">
        <div className=" flex flex-col mx-2 lg:flex-row lg:items-center justify-center gap-14 mb-10">
          {/* <div className=" flex items-center gap-3">
            <Image src={location} alt="location" />
            <div>
              <p className=" text-textColor font-[300]">العنوان الخاص بنا</p>
              <p className=" text-textColor font-[700]">
                القارهر - الدقي - وسط البلد عمراه رقم 5
              </p>
            </div>
          </div> */}
          <div className=" flex items-center gap-3">
            <Image
              src={phone}
              alt="location"
            />
            <div>
              <p className=" text-textColor font-[300]">اتصل بنا في اي وقت</p>
              <p className=" text-textColor font-[700]">+201080556105</p>
            </div>
          </div>
          <div className=" flex items-center gap-3">
            <Image
              src={email}
              alt="location"
            />
            <div>
              <p className=" text-textColor font-[300]">
                تواصل معنا عبر البريد
              </p>
              <MyLink
                href={
                  "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=Down Town.register@gmail.com"
                }
                className=" text-textColor font-[700]"
              >
                Down Town.register@gmail.com
              </MyLink>
            </div>
          </div>
        </div>

        <div className="bg-[#695750] rounded-[30px] py-12 mx-2 lg:mx-16">
          <div className="  flex flex-col lg:flex-row  justify-center gap-[4rem] lg:gap-[10rem]  border-b border-[#65686B] w-fit mx-auto pb-16 px-10">
            <div className=" flex items-center flex-wrap gap-5">
              <Image
                src={logo}
                alt="logo"
              />
              <p className=" text-textColor font-[500] max-w-[540px]">
                منصة Down Town هي منصة تهدف إلي تطوير العملية التعليمية لمساعدة
                الطالب في تحقق النجاح في جميع المواد والتفوق بها وذلك من خلال
                الخدمات التي نقدمها.
              </p>
              <div>
                <p className=" text-25 text-white font-[500] mb-3">
                  تابعنا علي منصات التواصل الإجتماعي
                </p>
                <div className=" flex items-center gap-3">
                  <MyLink
                    href={
                      "https://www.facebook.com/profile.php?id=61560710972147"
                    }
                    target="_blank"
                  >
                    <Image
                      src={faceboock}
                      alt="faceboock"
                    />
                  </MyLink>
                  <MyLink
                    href={"https://t.me/Down Town"}
                    target="_blank"
                  >
                    <Image
                      src={telegram}
                      alt="telegram"
                    />
                  </MyLink>
                  <MyLink
                    href={"https://wa.me/01080556105"}
                    target="_blank"
                  >
                    <Image
                      src={whatsApp}
                      alt="whatsApp"
                    />
                  </MyLink>

                  {/* <Image src={linkedin} alt="linkedin" /> */}
                  {/* <Image src={twitter} alt="twitter" /> */}
                  {/* <Image src={instegram} alt="instegram" /> */}
                </div>
              </div>
            </div>
            {/* <div>
              <p className=" text-25 text-white font-[500] mb-2">
                راوبط الموقع
              </p>
              <ul className=" flex flex-col gap-2">
                <li>
                  <MyLink
                    href={"/"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    الرئيسية
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    عنا
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    خدماتنا
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    آراء العملاء
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    المدونة
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    تواصل معنا
                  </MyLink>
                </li>
              </ul>
            </div> */}
            {/* <div>
              <p className=" text-25 text-white font-[500] mb-2">
                خدمات المنصة
              </p>
              <ul className=" flex flex-col gap-2">
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    مواعيد سناتر
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    كورسات لمدرس واحد
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    لاكتر من مدرس مع بعض
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    كورسات فقط
                  </MyLink>
                </li>
                <li>
                  <MyLink
                    href={"#"}
                    className=" text-16 text-textColor font-[500]"
                  >
                    برمجه منصتك الخاصه
                  </MyLink>
                </li>
              </ul>
            </div> */}
          </div>
          <div className=" flex flex-col mx-2 gap-3 items-center justify-center mt-10">
            <p className=" text-16 text-textColor text-center font-[500] text-wrap">
              © جميع الحقوق محفوظة، Rascoda | صنع بكل حب 💜 تنفيذ و تطوير{" "}
              <MyLink
                href="http://rascoda.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#ae75fd] mx-2"
              >
                Rascoda.com
              </MyLink>
            </p>
            <ul className=" flex gap-3">
              <li>
                <MyLink
                  href={"/privacyPolicy"}
                  className=" text-14 text-textColor"
                >
                  سياسة الخصوصية
                </MyLink>
              </li>
              <li>
                <MyLink
                  href={"/termsAndConditions"}
                  className=" text-14 text-textColor"
                >
                  الشروط والأحكام
                </MyLink>
              </li>
              {/* <li>
                <MyLink href={"#"} className=" text-14 text-textColor">
                  تواصل معنا
                </MyLink>
              </li> */}
            </ul>
            <MyLink
              href="http://rascoda.com"
              target="_blank"
              rel="noopener noreferrer"
              className="block text-center mx-auto"
            >
              <Image
                width={120}
                alt="Rascoda"
                src={rascoda}
                className="self-center mt-2.5 max-w-full aspect-[5.26] w-[124px]"
              />
            </MyLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;
